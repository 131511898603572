import React from "react";
import ChartConsultasRealizadas from "../../_metronic/layout/components/charts/ChartConsultasRealizadas";
import ChartConsultas from "../../_metronic/layout/components/charts/ChartConsultas";


export const RelatorioConsultas = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  return (<>
    <div className="row">
      <h1 className="content-subtitle">Relatórios de Consultas</h1>
    </div>
    <div className="row pb-20">
      <h1 className="content-subtitle-span">Informações referentes as consultas realizadas e perdidas</h1>
    </div>

    <div className="row">
      <div className="col-8">
        <ChartConsultasRealizadas height={80} title="Consultas Realizadas" />
      </div>
      <div className="col-4">
        <ChartConsultas class="pb-40"/>
      </div>
    </div>
    <br/><br/>
    <div className="row">
      <div className="col-8">
        <ChartConsultasRealizadas height={80} title="Consultas Perdidas" />
      </div>
      <div className="col-4">
        <ChartConsultas class="pb-40"/>
      </div>
    </div>

  </>);
};

