import React from 'react'
import { Bar } from "react-chartjs-2";

// const data = {
//   labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
//   datasets: [
//     {
//       label: 'Cadastrados deste mês',
//       data: [20, 22, 20, 15, 20, 18, 20, 22, 20, 15, 20, 18,],
//       backgroundColor: '#46eccb',
//     },
//     {
//       label: 'Cadastrados Antigos',
//       data: [25, 26, 25, 20, 30, 25, 25, 26, 25, 20, 30, 25],
//       backgroundColor: '#255fe3',
//     },
//   ],
// }

const data = {
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  datasets: [
    {
      label: 'Agendadas em Domicílio',
      data: [12, 19, 3, 5, 2, 3, 2, 3, 20, 5, 1, 4],
      backgroundColor: 'rgb(54, 162, 235)',
    },
    {
      label: 'Agendadas em Consultório',
      data: [2, 3, 20, 5, 1, 4, 3, 10, 13, 15, 22, 30],
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      label: 'Caráter de Prontidão',
      data: [3, 10, 13, 15, 22, 30, 12, 19, 3, 5, 2, 3],
      backgroundColor: 'rgb(75, 192, 192)',
    },
  ],
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    display: false
  }
}

const ChartConsultasRealizadas = (props) => (
  <>
    <div className="row">
      <div className="col-12">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-chart`}>
          {/* begin::Header */}
          <div className="card-header border-0 pb-0 card-chart">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">{props.title || 'Gráfico'}</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0 pb-20" style={{ paddingTop: 0 }}>
            <div className="col-12">
              <Bar data={data} options={options} height={props.height} />
              <div className="row">
                <i class="fa fa-circle" style={{ color: 'rgb(54, 162, 235)', paddingRight: '1rem', paddingLeft: '4rem' }}></i>Agendadas em Domicílio
                <i class="fa fa-circle" style={{ color: 'rgb(255, 99, 132)', paddingRight: '1rem', paddingLeft: '4rem' }}></i>Agendadas em Consultório
                <i class="fa fa-circle" style={{ color: 'rgb(75, 192, 192)', paddingRight: '1rem', paddingLeft: '4rem' }}></i>Caráter de Prontidão
              </div>
            </div>
          </div>
        </div>
        {/* end::Tiles Widget 1 */}
      </div>

    </div>
  </>
)

export default ChartConsultasRealizadas