import React from "react";
import ChartRelatorioFinanceiro from "../../_metronic/layout/components/charts/ChartRelatorioFinanceiro";
import ChartRelatorioFinanceiroMensal from "../../_metronic/layout/components/charts/ChartRelatorioFinanceiroMensal";

export const RelatorioFinanceiro = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  return (<>
    <div className="row">
      <h1 className="content-subtitle">Relatórios Financeiros</h1>
    </div>
    <div className="row pb-20">
      <h1 className="content-subtitle-span">Evolução do faturamento ao longo do tempo</h1>
    </div>

    <div className="row">
      <div className="col-8">
        <ChartRelatorioFinanceiroMensal height={80} title="Gráfico" />
      </div>
      <div className="col-4">
        <ChartRelatorioFinanceiro title="Anual" />
      </div>
    </div>

  </>);
};

