import React from 'react'
import { Line } from "react-chartjs-2";

const data = {
  labels: ['2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30'],
  datasets: [
    {
      label: 'azul',
      data: [23 ,19 ,24 ,21 ,21 ,19 ,18 ,24 ,20 ,20 ,28 ,30 ,35 ,36 ,30],
      fill: true,
      backgroundColor: '#c9e3ff87',
      borderColor: '#007bff',
      tension: 0.2,
    },
    {
      label: '',
      data: [22, 23, 24, 24, 23, 22, 22, 23, 22, 22, 26, 28, 32, 34, 32],
      fill: true,
      backgroundColor: '#f8e3e785',
      borderColor: '#ecb4bd',
      tension: 0.2,
    },
  ],
}

const options = {
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: false,
        suggestedMin: 0,
        suggestedMax: 130
      },
    },],

  },
  legend: {
    display: false,
  }

}

const ChartRelatorioFinanceiroMensal = (props) => (
  <>
    <div className="row">
      <div className="col-12">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-chart`}>
          {/* begin::Header */}
          <div className="card-header border-0 pb-0 card-chart">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">{props.title || ''}</div>
              </div>
            </div>
          </div>
          <hr/>
          <div className="card-body d-flex flex-column px-0 pb-20" style={{ paddingTop: 0 }}>
            <div className="col-12">
              <Line data={data} options={options}  height={props.height} />
            </div>
          </div>
        </div>
        {/* end::Tiles Widget 1 */}
      </div>

    </div>
  </>
)

export default ChartRelatorioFinanceiroMensal