import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

import {
  Button,
  ButtonToolbar,
} from "react-bootstrap";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;


export default class PatientTable extends React.Component {
  state = {
    pacientes: []
  }
  componentDidMount() {
    axios.get('pacientes')
      .then(res => {
        const pacientes = res.data;
        console.log(pacientes);
        this.setState({ pacientes });
      })
  }

  render() {
    return (
      <Paper  >
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Pacientes</TableCell>
              <TableCell align="left">Data</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.pacientes.map(row => (
              <TableRow key={row.nome}>
                <TableCell component="th" scope="row">
                  <img src="https://placehold.it/50x50" className="image-user" />
                  {row.nome} {row.sobrenome}
                </TableCell>
                <TableCell align="left">
                  {row.aniversario}
                </TableCell>
                <TableCell align="left">
                  {row.situacao}
                </TableCell>
                <TableCell align="left">
                  <ButtonToolbar className="toolbar-table">
                    {/* <Button size="sm" variant="secondary" className="btn-table-custom">
                      <span className="symbol-label bg-light-light">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                          className="align-self-center"
                        ></SVG>
                      </span>
                    </Button> */}
                    <Button size="sm" variant="secondary" className="btn-table-custom" href={`/paciente/${row.id}`}>
                      <Icon className="icon-view">visibility</Icon>
                    </Button>
                    {/* <Button size="sm" variant="secondary" className="btn-table-custom">
                      <Icon className="icon-delete">delete</Icon>
                    </Button> */}
                  </ButtonToolbar>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}