import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

import {
  Button,
  ButtonToolbar,
} from "react-bootstrap";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

function renderStart(star) {
  var rows = [];
  for (var i = 0; i < star; i++) {
    rows.push(
      <SVG key={Math.random().toString()} style={{ backgroundColor: 'white', width: 20 }}
        src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")}
        className="align-self-center"
      />
    );
  }
  for (var i = star; i < 5; i++) {
    rows.push(
      <SVG key={Math.random().toString()} style={{ backgroundColor: 'white', width: 20 }}
        src={toAbsoluteUrl("/media/svg/icons/General/Star-Inverted.svg")}
        className="align-self-center"
      />
    );
  }
  console.log(rows.length)
  return rows;
}


export default class MedicTable extends React.Component {
  state = {
    medicos: [],
    showAction: true,
  }
  componentDidMount() {
    axios.get('medicos')
      .then(res => {
        const medicos = res.data;
        if (this.props.showAction != undefined) {
          const showAction = this.props.showAction;
          this.setState({ showAction });
        }
        // console.log(medicos);
        this.setState({ medicos });
      })
  }

  render() {
    return (
      <Paper className="mb-2" >
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>Médico</TableCell>
              <TableCell align="center">Ano de cadastro</TableCell>
              <TableCell align="left">Classificação</TableCell>
              <TableCell align="right">Especialidade</TableCell>
              <TableCell align="right">Status</TableCell>
              {this.state.showAction && <TableCell align="center">Ações</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.medicos.map(row => (
              <TableRow key={row.nome}>
                <TableCell component="th" scope="row">
                  <img src="https://placehold.it/50x50" className="image-user" />
                  {row.nome}
                </TableCell>
                <TableCell align="center">
                  {row.ano_formatura}
                </TableCell>
                <TableCell align="left">
                  <span className="symbol-label bg-light-light">
                    {renderStart(row.classificacao)}
                  </span>
                </TableCell>
                <TableCell align="left">
                  {row.especialidade}
                </TableCell>
                <TableCell align="left">
                  {row.status}
                </TableCell>
                {this.state.showAction &&
                  <TableCell align="left">
                    <ButtonToolbar className="toolbar-table">
                      {/* <Button size="sm" variant="secondary" className="btn-table-custom">
                        <span className="symbol-label bg-light-light">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            className="align-self-center"
                          ></SVG>
                        </span>
                      </Button> */}
                      <Button size="sm" variant="secondary" className="btn-table-custom" href={`/medico/${row.id}`}>
                        <Icon className="icon-view">visibility</Icon>
                      </Button>
                      {/* <Button size="sm" variant="secondary" className="btn-table-custom">
                        <Icon className="icon-delete">delete</Icon>
                      </Button> */}
                    </ButtonToolbar>
                  </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}