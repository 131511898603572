import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';

import {
  Button,
  Form,
  Col,
} from "react-bootstrap";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export default class MedicForm extends React.Component {
  state = {
    medico: {
      nome: '',
      instituicao: '',
      ano_formatura: '',
      cep: '',
      endereco: '',
      cpf: '',
      telefone: '',
      celular: '',
      sexo: '',
      crm: '',
      especialidade: '',
      rqe: '',
      consultorio: '',
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
      email: '',
      raio_atendimento: '',
      numero_cartao: '',
      vencimento: '',
      codigo: '',
      agencia: '',
      tipo_conta: '',
      numero_conta: '',
      meios_pagamentos: '',
      valor_consulta: '',

    }
  }
  componentDidMount() {
    const id = this.props.id;
    axios.get('medicos/' + id)
      .then(res => {
        const medico = res.data;
        console.log(medico);
        this.setState({ medico });
      })
  }

  render() {
    return (
      <Form>
        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <div className="patiente-name">
                <img src="https://placehold.it/100x100" className="image-patiente" />
                {this.state.medico.nome}
              </div>
            </Form.Group>
          </Col>
        </Form.Row>

        <br/>
        <h5 className="with-separator"><span>Informações Pessoais</span></h5>
        <br/>

        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label className="text-bold">Nome Completo</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.nome} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={10}>
            <Form.Group>
              <Form.Label className="text-bold">Instituição de Ensino Superior</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.instituicao} />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label className="text-bold">Telefone</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.ano_formatura} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="text-bold">CEP</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.cep} />
            </Form.Group>
          </Col>
          <Col md={9}>
            <Form.Group>
              <Form.Label className="text-bold">Endereço</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.endereco} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="text-bold">CPF</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.cpf} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Telefone</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.telefone} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Celular</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.celular} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Sexo</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.sexo} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">crm</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.crm} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Especialização</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.especialidade} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>RQE</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.rqe} />
            </Form.Group>
          </Col>
        </Form.Row>

        <br/>
        <br/>
        <br/>
        <h5 className="with-separator"><span>Informações da Clínica</span></h5>
        <br/>

        <Form.Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label className="text-bold">Nome do Consultório</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.consultorio} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>CEP do Consultório</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.cep} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Bairro</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.bairro} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Cidade</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.cidade} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.estado} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label className="text-bold">Endereco</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.endereco} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Complemento</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.complemento} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={8}>
            <Form.Group>
              <Form.Label className="text-bold">E-mail</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.email} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">Raio de Atendimento</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.raio_atendimento+" Km"} />
            </Form.Group>
          </Col>
        </Form.Row>

        <br/>
        <br/>
        <br/>
        <h5 className="with-separator"><span>Informações Bancárias</span></h5>
        <br/>  

        <Form.Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label className="text-bold">Proprietário do Cartão</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.nome} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>CPF do Portador</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.cpf} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Número do Cartão</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.numero_cartao} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Vencimento do Cartão</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.vencimento} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Código do Banco</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.codigo} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Agência</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.agencia} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Tipo de Conta</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.tipo_conta} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Número da Conta</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.numero_conta} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={8}>
            <Form.Group>
              <Form.Label className="text-bold">Meios de pagamento aceito</Form.Label>
              <Form.Control type="text" readOnly value={this.state.medico.meios_pagamentos} />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">Valor da Consulta</Form.Label>
              <Form.Control type="text" readOnly value={'R$ ' + this.state.medico.valor_consulta} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}></Col>

          <Col md={2}>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="button"
                className={`form-control btn-create-account h-auto btn-radius py-3 px-12`}
                value="Ativação"
              />
            </div>
          </Col>

          <Col md={2}>
            <input
              type="button"
              className={`form-control btn-create-account-inverted h-auto btn-radius py-3 px-12`}
              value="Revogação"
            />
          </Col>

          <Col md={2}>
            <input
              type="button"
              className={`form-control btn-delete-account h-auto btn-radius py-3 px-12`}
              value="Exclusão"
            />
          </Col>

          <Col md={3}></Col>
        </Form.Row>
        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
      </Form>
    )
  }
}