import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';

import {
  Button,
  Form,
  Col,
} from "react-bootstrap";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export default class PatientForm extends React.Component {
  state = {
    paciente: {
      nome: '',
      sobrenome: '',
      email: '',
      telefone: '',
      aniversario: '',
      cpf: '',
      profissao: '',
    }
  }
  componentDidMount() {
    const id = this.props.id;
    axios.get('pacientes/' + id)
      .then(res => {
        const paciente = res.data;
        console.log(paciente);
        this.setState({ paciente });
      })
  }

  render() {
    return (
      <Form>
        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <div className="patiente-name">
                <img src="https://placehold.it/100x100" className="image-patiente" />
                {this.state.paciente.nome + " " + this.state.paciente.sobrenome}
              </div>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label className="text-bold">Nome Completo</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.nome + " " + this.state.paciente.sobrenome} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">Email</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.email} />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">Telefone</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.telefone} />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group>
              <Form.Label className="text-bold">Data Nascimento</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.aniversario} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="text-bold">CPF</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.cpf} />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group>
              <Form.Label className="text-bold">Profissão</Form.Label>
              <Form.Control type="text" readOnly value={this.state.paciente.profissao} />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group style={{ marginBottom: 4 }}>
              <Form.Label className="text-bold">Dependentes</Form.Label>
              <Form.Control type="text" readOnly />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group style={{ marginBottom: 4 }}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control type="text" readOnly />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Control type="text" readOnly />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Control type="text" readOnly />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={3}></Col>

          <Col md={2}>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="button"
                className={`form-control btn-create-account h-auto btn-radius py-3 px-12`}
                value="Ativação"
              />
            </div>
          </Col>

          <Col md={2}>
            <input
              type="button"
              className={`form-control btn-create-account-inverted h-auto btn-radius py-3 px-12`}
              value="Revogação"
            />
          </Col>

          <Col md={2}>
            <input
              type="button"
              className={`form-control btn-delete-account h-auto btn-radius py-3 px-12`}
              value="Exclusão"
            />
          </Col>

          <Col md={3}></Col>
        </Form.Row>
        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
      </Form>
    )
  }
}