import React from 'react'
import { Pie } from "react-chartjs-2";

import {
  Badge,
  //ButtonToolbar,
} from "react-bootstrap";

const data = {
  labels: ['Time 1', 'Time 2', 'Time 3', 'Time 4'],
  datasets: [
    {
      //label: '# of Votes',
      data: [12, 19, 3, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
    
  ],
}

const options = {
  legend: {
    display: false
  }
}

const ChartConsultas = (props) => (
  <>
    <div className="row">
      <div className="col-12">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-chart ${props.class}`}>
          {/* begin::Header */}
          <div className="card-header border-0 pb-0 card-chart">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">Relatório de Consultas</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0 pb-10" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-8">
                <Pie data={data} options={options} />
              </div>
              <div className="col-4 mt-4">
                <i className="fa fa-circle" style={{color:'rgba(255, 99, 132, 1)',}}></i> Time 1 <br/><br/>
                <i className="fa fa-circle" style={{color:'rgba(54, 162, 235, 1)',}}></i> Time 2 <br/><br/>
                <i className="fa fa-circle" style={{color:'rgba(255, 206, 86, 1)',}}></i> Time 3 <br/><br/>
                <i className="fa fa-circle" style={{color:'rgba(75, 192, 192, 1)',}}></i> Time 4
              </div>
            </div>
          </div>
        </div>
        {/* end::Tiles Widget 1 */}
      </div>

    </div>
  </>
)

export default ChartConsultas