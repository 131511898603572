import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // + auth/

export const LOGIN_URL = "auth/login";
export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = "auth/forgot-password";
export const ME_URL = "auth/me";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let token = localStorage.getItem('token');
  axios.defaults.headers.common = {'Authorization': `bearer ${token}`};
  return axios.get(ME_URL);
}
