import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { TilesWidget1 } from "../../_metronic/_partials/widgets";
import NotificacaoForm from "../../_metronic/layout/components/forms/NotificacaoForm";

export const Notificacoes = (props) => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  
  return (<>
    <div className="row">
      <h1 className="content-subtitle">Notificações</h1>
    </div>
    <div className="row pb-40">
      <h1 className="content-subtitle-span">É possível definir um público para recebimento das notificações 
      <br/> personalizadas pelo administrador do sistema.</h1>
    </div>

    <div className="row">
      <div className="col-12">
        <div className="card card-custom card-table">
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">Crie uma Notificação</div>
              </div>
            </div>
            <hr />
          </div>
          <div className="card-body d-flex flex-column px-0">
            <NotificacaoForm/>
          </div>
        </div>
      </div>
    </div>
  </>);
};
