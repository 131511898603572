import React from 'react'
import { Line } from "react-chartjs-2";

const data = {
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
  datasets: [
    {
      label: '',
      data: [20, 25, 21, 30, 32, 35],
      fill: false,
      backgroundColor: '#ffffff',
      borderColor: '#007bff',
      tension: 0.3,
    },
  ],
}

const options = {
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: false,
      },
      gridLines: {
        color: "white",
      }
    },],
    xAxes: [{
      gridLines: {
        color: "white",
      }
    },],
  },
  legend: {
    display: false
  },
}

const ChartRelatorioFinanceiro = (props) => (
  <>
    <div className="row">
      <div className="col-12">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-chart`}>
          {/* begin::Header */}
          <div className="card-header border-0 pb-0 card-chart">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">{props.title || 'Relatórios Financeiros'}</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0 pb-20" style={{ paddingTop: 0 }}>
            <div className="col-12">
              <Line data={data} options={options} />
            </div>
          </div>
        </div>
        {/* end::Tiles Widget 1 */}
      </div>

    </div>
  </>
)

export default ChartRelatorioFinanceiro