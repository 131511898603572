import React from 'react'
import { Bar } from "react-chartjs-2";

const data = {
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  datasets: [
    {
      label: 'Cadastrados deste mês',
      data: [20, 22, 20, 15, 20, 18, 20, 22, 20, 15, 20, 18,],
      backgroundColor: '#46eccb',
    },
    {
      label: 'Cadastrados Antigos',
      data: [25, 26, 25, 20, 30, 25, 25, 26, 25, 20, 30, 25],
      backgroundColor: '#255fe3',
    },
  ],
}

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        gridLines: {
          color: "white",
        },
      },
    ],
    y: {
      grid: {
        borderColor: 'white'
      }
    },
    xAxes: [
      {
        stacked: true,
        gridLines: {
          color: "white",
        }
      },
    ],
  },
  legend: {
    display: false
  },
}

const ChartRelatorioMedicoNaoPagante = (props) => (
  // <>
  //   <div className="row">
  //     <div className="col-12">
  //       {/* begin::Tiles Widget 1 */}
  //       <div className={`card card-custom card-chart`}>
  //         {/* begin::Header */}
  //         <div className="card-header border-0 pb-0 card-chart">
  //           <div className="card-title">
  //             <div className="card-label">
  //               <div className="font-weight-bolder table-title"></div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="card-body d-flex flex-column px-0 pb-20" style={{ paddingTop: 0 }}>
  //           <div className="col-12">
              <Bar data={data} options={options} height={props.height}/>
  //           </div>
  //         </div>
  //       </div>
  //       {/* end::Tiles Widget 1 */}
  //     </div>

  //   </div>
  // </>
)

export default ChartRelatorioMedicoNaoPagante