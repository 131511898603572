import React from "react";
import ChartRelatorioMedicoPagante from "../../_metronic/layout/components/charts/ChartRelatorioMedicoPagante";
import ChartRelatorioMedicoNaoPagante from "../../_metronic/layout/components/charts/ChartRelatorioMedicoNaoPagante";

export const RelatorioCadastro = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  return (<>
    <div className="row">
      <h1 className="content-subtitle">Relatórios de Cadastramento</h1>
    </div>
    <div className="row pb-20">
      <h1 className="content-subtitle-span">Cadastramento no aplicativo ao longo do tempo</h1>
    </div>

    <div className="row">
      <div className="col-12">
        <div className={`card card-custom card-table`}>
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title" style={{fontSize: '2.5rem'}}>Médicos Pagantes</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0">
            <ChartRelatorioMedicoPagante height={80}/>
            <div className="row">
                <i class="fa fa-circle" style={{color:'#46eccb', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados deste mês
                <i class="fa fa-circle" style={{color:'#255fe3', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados Antigos
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/><br/>
    <div className="row">
      <div className="col-12">
        <div className={`card card-custom card-table`}>
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title" style={{fontSize: '2.5rem'}}>Médicos Não Pagantes</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0">
            <ChartRelatorioMedicoNaoPagante height={80}/>
            <div className="row">
                <i class="fa fa-circle" style={{color:'#46eccb', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados deste mês
                <i class="fa fa-circle" style={{color:'#255fe3', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados Antigos
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/><br/>
    <div className="row">
      <div className="col-12">
        <div className={`card card-custom card-table`}>
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title" style={{fontSize: '2.5rem'}}>Pacientes</div>
              </div>
            </div>
          </div>
          <div className="card-body d-flex flex-column px-0">
            <ChartRelatorioMedicoNaoPagante height={80}/>
            <div className="row">
                <i class="fa fa-circle" style={{color:'#46eccb', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados deste mês
                <i class="fa fa-circle" style={{color:'#255fe3', paddingRight:'1rem', paddingLeft:'4rem'}}></i>Cadastrados Antigos
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </>);
};

