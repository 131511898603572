import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import Dropdown from "react-bootstrap/Dropdown";
import Button from '@material-ui/core/Button';

export function Topbar() {

  return (
    <div className="topbar">
      <Dropdown className="dropdownTopBar">
        <Dropdown.Toggle>
          Relatórios
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/relatorios-consultas">Relatórios de Consultas</Dropdown.Item>
          <Dropdown.Item href="/relatorios-cadastramento">Relatórios de Cadastramento</Dropdown.Item>
          <Dropdown.Item href="/relatorios-financeiros">Relatórios Financeiro</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <div className="dropdown">
        <Button variant="contained" className="dropdownTopBar" href="/pacientes">
          Paciente
        </Button>
      </div>

      <div className="dropdown">
        <Button variant="contained" className="dropdownTopBar" href="/medicos">
          Médico
        </Button>
      </div>

      <div className="dropdown">
        <Button variant="contained" className="dropdownTopBar" href="/notificacoes">
          Notificações
        </Button>
      </div>

      {/* <div className="dropdown">
        <Button variant="contained" className="dropdownTopBar" href="/planos">
          Planos
        </Button>
      </div> */}

      <div className="dropdown">
        <Button variant="contained" className="dropdownTopBar" href="/logout">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/logout.svg")}
            />
          </span>
        </Button>
      </div>

    </div>
  );
}
