import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { TilesWidget1 } from "../../_metronic/_partials/widgets";
import MedicTable from "../../_metronic/layout/components/tables/MedicTable";

export const Medic = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");

  return (<>
    <div className="row pb-40">
      <h1 className="content-subtitle">Médicos</h1>
    </div>
    
    <div className="row">
      <div className="col-12">
        <div className="card card-custom card-table">
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">Lista de Médicos</div>
              </div>
            </div>
            <div className="card-toolbar">

            </div>
            <hr/>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body d-flex flex-column px-0">
            <MedicTable/>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </div>
    </div>
  </>);
};
