import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { TilesWidget1, TilesWidget3 } from "../../_metronic/_partials/widgets";
import MedicTable from "../../_metronic/layout/components/tables/MedicTable";
import ChartConsultas from "../../_metronic/layout/components/charts/ChartConsultas";
import ChartRelatorioFinanceiro from "../../_metronic/layout/components/charts/ChartRelatorioFinanceiro";

export const DashboardPage = () => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  return (<>
    <div className="row pb-20">
      <h1 className="content-subtitle" style={{fontSize: '4.5rem'}}>Painel <br />Administrativo</h1>
    </div>

    <div className="row">
      <div className="col-8">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-table`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title">Lista de Médicos</div>
              </div>
            </div>
            <div className="card-toolbar">

            </div>
            <hr />
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body d-flex flex-column px-0">
            <MedicTable showAction={false}/>
          </div>
          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </div>
      <div className="col-4">
        <div className="row">
          <div className="col-12">
            <ChartConsultas/>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <ChartRelatorioFinanceiro/>
          </div>
        </div>
      </div>
    </div>
  </>);
};

