import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { TilesWidget1 } from "../../_metronic/_partials/widgets";
import MedicForm from "../../_metronic/layout/components/forms/MedicForm";

export const MedicInfo = (props) => {
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");
  const medicId = props.match.params.id
  return (<>
    <div className="row">
      <h1 className="content-subtitle">Detalhes do Médico</h1>
    </div>
    <div className="row pb-40">
      <h1 className="content-subtitle-span">Informações do médico associado</h1>
    </div>

    <div className="row">
      <div className="col-12">
        {/* begin::Tiles Widget 1 */}
        <div className={`card card-custom card-table`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <div className="card-title">
              <div className="card-label">
                <div className="font-weight-bolder table-title-centered">Médico</div>
              </div>
            </div>
            <hr />
            <div className="card-toolbar">
              <MedicForm id={medicId} />
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <div className="card-body d-flex flex-column px-0">

          </div>
          {/* end::Body */}
        </div>
        {/* end::Tiles Widget 1 */}
      </div>
    </div>
  </>);
};
