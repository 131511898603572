import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';

import {
  Button,
  Form,
  Col,
} from "react-bootstrap";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export default class NotificacaoForm extends React.Component {
  state = {
    paciente: {
      nome: '',
      sobrenome: '',
      email: '',
      telefone: '',
      aniversario: '',
      cpf: '',
      profissao: '',
    }
  }
  // componentDidMount() {
  //   const id = this.props.id;
  //   axios.get('pacientes/' + id)
  //     .then(res => {
  //       const paciente = res.data;
  //       console.log(paciente);
  //       this.setState({ paciente });
  //     })
  // }

  render() {
    return (
      <Form>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Check className="meddi-checkbox" type="checkbox" readOnly label="Enviar para todos"/>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Check className="meddi-checkbox" type="checkbox" readOnly label="Enviar para alguém específico"/>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Destinatário</Form.Label>
              <Form.Control type="text" readOnly as="select">
                <option></option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Dispositivo</Form.Label>
              <Form.Control type="text" readOnly as="select">
                <option></option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Selecionar a Localidade</Form.Label>
              <Form.Control type="text" readOnly as="select">
                <option></option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label className="text-bold">Título</Form.Label>
              <Form.Control type="text" readOnly/>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label className="text-bold">Mensagem</Form.Label>
              <Form.Control type="text" readOnly as="textarea" rows={3}/>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Agendar</Form.Label>
              <Form.Control type="text" readOnly as="select">
                <option></option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label className="text-bold">Data de Envio</Form.Label>
              <Form.Control type="date" readOnly />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={6}></Col>
          <Col md={3}>
            <input
              type="button"
              className={`form-control btn-create-account-inverted h-auto btn-radius py-3 px-12`}
              value="Cancelar"
            />
          </Col>
          <Col md={3}>
            <div className="form-group fv-plugins-icon-container">
              <input
                type="button"
                className={`form-control btn-create-account h-auto btn-radius py-3 px-12`}
                value="Enviar"
              />
            </div>
          </Col>
        </Form.Row>
        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
      </Form>
    )
  }
}